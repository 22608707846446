@media print {
    * {
        transition: none !important;
    }

    @page {
        margin: 2cm;
    }


    body {
        font-size: 12pt;
    }

    .gradientText,
    .gradientBg,
    .gradientBg span {
        color: black !important;
        background: initial !important;
        -webkit-text-fill-color: black !important;
    }


    // no display

    button,
    form,
    #kunden,
    #newsletter-anmeldung,
    #indexlogo {
        display: none;
    }

    .container {
        padding: 0;
    }

    .overlay,
    .hero-overlay {
        background: initial;
    }

    .hero-image {
        background-image: none !important;

        video {
            display: none !important;
        }

        #site-header {
            .nav {
                display: none;
            }

            .main-message,
            .main-message * {
                background: none;
                color: black;
                text-align: left;
                margin: 0;
                padding: 0;
                width: 100%;
            }
        }
    }

    section div.row {
        img {
            max-width: 50%;
        }
    }

    section.separator {
        background: initial;

        *,
        *:before,
        *:after {
            color: black !important;
            background: initial !important;
            -webkit-text-fill-color: black !important;
        }

        .carousel-inner {
            .item {
                page-break-inside: avoid;
            }

        }

        ol.carousel-indicators {
            display: none;
        }
    }

    #features .row>div>.row>div {
        page-break-inside: avoid;
    }

    #footer-sitemap {
        display: none;
    }

    a[href*="#top"] {
        display: none;
    }

    #footer,
    #footer * {
        background: initial;
        color: black !important;
    }

    .hero-image,
    .hero-image * {
        position: initial !important;
        height: auto !important;
        transform: none;
    }

    div.userlike {
        display: none !important;
    }

}
/* ----------------------------------------------------------------
	Cookie
-----------------------------------------------------------------*/

#cookie a {
    color: #fff;
    text-decoration: none;
}

#cookie a:hover {
    text-decoration: underline;
}

#cookie .cookie-content {
    display: flex;
    align-items: center;
    padding: 1rem;

    span {
        flex-grow: 1;
    }
}

#cookie {
    text-align: left;
    background-color: #3c3c3b;
    display: block;
    color: #fff;
    text-decoration: none;
    z-index: 10000;
    width: 100%;
    font-size: 12px;
    line-height: 20px;

    // overflow: hidden;
    span {
        margin-right: 1rem;
    }
}

#cookieCloser {
    text-decoration: none;
    line-height: 16px;
    color: #fff;
    margin: 0;
}
/* overwite */

section {
    padding: 50px 0;
}


// tns buttons
.tns-controls {
    button {
        color: $page-color-7;
        border: none;
        background: none;
        font-size: 3rem;
        transform: translateY(-50%);
        transform-origin: 50% 50%;
        transition: .1s ease transform;

        &:hover {
            background: none;
            transform: translateY(-50%) scale(1.2);
        }
    }

    button[data-controls="prev"] {
        position: absolute;
        top: 50%;
        left: 0;
    }

    button[data-controls="next"] {
        position: absolute;
        top: 50%;
        right: 0;
    }
}

button[data-action=stop] {
    display: none;
}

.separator {
    .text {
        padding: 3rem 0;
    }

    .tns-controls {
        button {
            color: $page-color-6;
        }
    }

    .logocarousel {
        padding-top: 3rem;
        padding-bottom: 3rem;
        display: flex;
        align-items: center;
        .tns-item {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                transform-origin: center;
                transform: scale(.8);
                max-height: 6rem;
            }
        }
    }

    &.overflowing {
        overflow: visible;

        button[data-controls=prev],
        button[data-controls=next],
        .tns-controls button[data-controls=prev],
        .tns-controls button[data-controls=next] {
            left: 10% !important;
            visibility: hidden;

            &:after {
                visibility: visible;
                content: '';
                position: absolute;
                width: 3.75rem;
                height: 3.75rem;
                border-bottom: 2px solid #fff;
                border-left: 2px solid #fff;
                top: 50%;
                left: 75%;
                margin: -1.875rem;
                transform: rotate(45deg);
                transition: all .3s ease-out;
            }
        }

        button[data-controls=next],
        .tns-controls button[data-controls=next] {
            right: 10% !important;
            left: auto !important;

            &:after {
                left: auto;
                right: 75%;
                margin: -1.875rem;
                transform: rotate(-135deg);
            }
        }

        .overlay {
            height: auto;
            min-height: 360px;

            .text {
                position: static;
                -webkit-transform: translatey(0%);
                transform: translatey(0%);
            }
        }
    }
}

#kunden section.separator .text a {
    min-height: 5rem;
    display: inline-block;
    overflow: visible;

    // margin: 0 1rem 1rem 0;
    img {
        max-height: 5rem;
        width: auto;
    }
}

// space between image and form
form#downloadfordata {
    margin-top: 5rem;
}

.row+.row {
    padding-top: 50px;
}

button:active,
button:focus {
    border-radius: 0;
    outline: none;
}

section#image-credits {
    ul {
        list-style-type: none;
        margin: 0;

        li {
            display: inline;

            &:not(:last-child):after {
                content: '; ';
            }
        }
    }
}

//fix userlike tracking image bug
.userlike-tracking-img {
    //just pull it out of the content flow & viewport
    position: absolute;
    left: -200vw;
    top: 0;
}

// contact form recall opt-in toggle

input#recall-opt-in~span {
    display: none;
}

input#recall-opt-in:checked~span {
    display: block;
}

@media (max-width: 450px) {
    .sm-text-left {
        display: block;
        // margin: 0 auto;
        // text-align: center;
        text-align: left;
    }
}

// @media screen and(max-width: 959px){
//     video#herovid-mobile{
//         display: initial
//     }
// }

// @media screen and (min-width: 960px){
//     video#herovid-desktop{
//         display: initial;
//     }
// }

// container image overwrite

.container img.drittsysteme {
    padding-top: 3rem;
}

// klinik liste overwrite

.klinik-list {
    .first-row {
        ul {
            margin-left: 0;
            .container {
                padding-left: 0;
                padding-right: 0;
            }
            div{
                margin-bottom: 1.5rem;
            }

            &>div {

                &>li {
                    margin-bottom: 4rem;

                    strong {
                        display: block;
                    }
                }
            }
        }
    }
}

/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/
@use "sass:math";

* {
    box-sizing: border-box;

    &::selection {
        background: $page-color-7;

        @media (prefers-color-scheme: dark) {
            & {
                background: $page-color-7-dark;
            }
        }

        color: $page-color-0;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-0-dark;
            }
        }
    }
}

body {
    &.menu-shown {
        overflow: hidden;
    }

    font-family: $page-font-family;
    font-size: $page-font-size;
    line-height: $page-line-height;
    color: $page-color-7;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-7-dark;
        }
    }

    background-color: $page-color-0;

    @media (prefers-color-scheme: dark) {
        & {
            background-color: $page-color-0-dark;
        }
    }

    font-weight: $page-font-weight-light;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $page-color-7;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-7-dark;
        }
    }

    margin-bottom: 2rem;
    font-weight: $page-font-weight-light;

    @media (prefers-color-scheme: dark) {
        & {
            font-weight: $page-font-weight-regular;
        }
    }

    font-family: $page-font-family-secondary;
    line-height: $page-line-height;
}

h1 {
    span.sr-only {
        display: none;
    }
}

h2 {
    color: $page-color-0;

    @media (prefers-color-scheme: light) {
        color: $page-color-7;
    }

    font-size: $grosse-hl;
    margin-bottom: 3rem;
}

h3 {
    font-size: $mittlere-hl;

    &.big-text {
        margin-top: 0;
        font-size: 1.1em;
        font-weight: 400;
    }
}

p {
    font-weight: $page-font-weight-light;
    margin: 0;

    +p {
        padding-top: 1.5rem;
    }
}

b,
strong {
    font-weight: $page-font-weight-bold;
    letter-spacing: 2;

    a {
        font-weight: $page-font-weight-bold;
    }
}

b {
    font-weight: $page-font-weight-regular;
}

a {
    color: $page-color-7;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-7-dark;
            text-decoration: none;
        }
    }

    font-weight: $page-font-weight-light;

    &:hover {
        color: $page-color-7;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-7-dark;
            }
        }
    }

    h4:hover {
        text-decoration: underline;
    }
}

form label {
    color: $page-color-7;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-7-dark;
        }
    }

    font-weight: $page-font-weight-light;
    font-family: $page-font-family-secondary;
    line-height: $page-line-height;
}

blockquote {
    margin: 2rem;
    font-style: italic;
    color: $page-color-7;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-7-dark;
        }
    }

    font-family: $page-font-family-secondary;
}

.nobr {
    white-space: nowrap;
    hyphens: none;
}

.caption {
    text-align: center;
    margin-bottom: 70px;
}

ul {
    margin: 20px 0 20px 20px;
    padding: 0;

    li {
        font-family: $page-font-family;
        font-size: 18px;
        line-height: 3rem;
        list-style-position: outside;
        color: $page-color-7;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-7-dark;
            }
        }
    }

    &.inline-badges {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;

        li {
            list-style-type: none;
            display: inline-block;
            margin: 0 .5em .5em 0;
            padding: 0 .5em;
            background-color: $page-color-2;

            @media (prefers-color-scheme: dark) {
                & {
                    background-color: $page-color-2-dark;
                }
            }

            color: $page-color-0;

            @media (prefers-color-scheme: dark) {
                & {
                    color: $page-color-0-dark;
                }
            }

            border-radius: 1.6em;
            font-weight: 600;
            font-size: .8em;
        }
    }
}

/* gradients */

.gradientText {
    color: $page-color-3;
    background: linear-gradient(to right, rgba(149, 193, 31, 1) 0%, rgba(2, 157, 191, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    line-height: 5rem;
    font-family: $page-font-family-secondary;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}

.gradientBg {
    line-height: 1.3;
    padding: 2px 0 .5rem;
    width: 100%;
    max-width: 800px;
    margin: 0;

    span {
        background: $page-color-3;
        background: linear-gradient(to right, rgba(149, 193, 31, 1) 0%, rgba(2, 157, 191, 1) 100%);
        padding: .2rem .8rem;
        font-size: 3.5rem;
        color: $page-color-0;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-0-dark;
            }
        }

        text-transform: uppercase;
        font-weight: 400;
        font-family: $page-font-family-secondary;
        text-align: left;
        margin: 0;
        display: inline;
        /* Needs prefixing */
        box-decoration-break: clone;
    }
}

// whitepaper download
form.on-white {

    input,
    textarea {
        margin-bottom: 1rem;
        color: $page-color-5;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-5-dark;
            }
        }

        &[type="checkbox"] {
            float: left;
            margin-right: .5rem;
        }

        &:focus {
            color: $page-color-5;
            caret-color: $page-color-5;

            @media (prefers-color-scheme: dark) {
                & {
                    color: $page-color-5-dark;
                }
            }
        }
    }

    select {
        margin-bottom: 1rem;
        appearance: none;
        -webkit-appearance: none;
        border-radius: 0;
        -webkit-border-radius: 0;
        color: $page-color-3;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-3-dark;
            }
        }

        &::placeholder {
            color: $page-color-3;

            @media (prefers-color-scheme: dark) {
                & {
                    color: $page-color-3-dark;
                }
            }
        }
    }

    label {
        color: $page-color-5;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-5-dark;
            }
        }
    }

    button {
        margin: 0 auto;
        display: block;
    }

    ::-webkit-input-placeholder {
        color: $page-color-3 !important;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-3-dark !important;
            }
        }

        opacity: 1.0;
    }

    :-moz-placeholder {
        color: $page-color-3 !important;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-3-dark !important;
            }
        }

        opacity: 1.0;
    }

    ::-moz-placeholder {
        color: $page-color-3 !important;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-3-dark !important;
            }
        }

        opacity: 1.0;
    }

    :-ms-input-placeholder {
        color: $page-color-3 !important;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-3-dark !important;
            }
        }

        opacity: 1.0;
    }

    &:after {
        content: ' ';
        display: block;
        height: 0px;
        width: 100px;
        float: none;
        clear: both;
    }
}

button.button {
    &.big {
        font-size: 1.2em;
    }

    &.centered {
        display: block;
        margin: 2rem auto;
    }

    &.download:before {
        content: '\f078';
        font-family: 'FontAwesome', sans-serif;
        display: inline-block;
        margin-right: .5em;
    }
}

@media (max-width: 768px) {

    h1,
    h2 {
        font-size: 2.5rem;

        &.gradientBg {
            span {
                font-size: 2.5rem;
            }
        }
    }

    h2,
    h3 {
        font-size: 2.3rem;
        margin-bottom: 1rem;
        line-height: 3.5rem;

        &.gradientText {
            line-height: 3rem;
        }
    }

    h3 {
        font-size: 1.3rem;
        line-height: 1.6;
    }

    p,
    label {
        font-size: 1.3rem;
        line-height: 1.6;
    }

    ul {
        li {
            font-size: 1.3rem;
            line-height: 1.6;
        }
    }
}

/* ----------------------------------------------------------------
	Button
-----------------------------------------------------------------*/

button,
.button {
    padding: 0 1rem;
    margin: 2rem auto;
    background: transparent;
    border-width: 3px;
    border-style: solid;
    border-color: $page-color-2; // fix for safari
    -moz-border-image: -moz-linear-gradient(top left, #95c11f 0%, #029dbf 100%);
    -webkit-border-image: -webkit-linear-gradient(top left, right top, #95c11f, #029dbf);
    border-image: linear-gradient(to bottom right, #95c11f 0%, #029dbf 100%);
    -webkit-border-image-slice: 1;
    border-image-slice: 1;
    color: $page-color-7;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-7-dark;
        }
    }

    height: 2.75em;
    font-family: $page-font-family;
    font-size: math.round($page-font-size * 0.8);

    a {
        outline: none;
    }

    &.small {
        font-size: math.round($page-font-size * .6);
    }
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    border: 3px solid $page-color-2;
    background-color: $page-color-2;
    color: $page-color-0;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-0-dark;
        }
    }
}

.overlay,
.main-message {

    button,
    .button {
        color: $page-color-0;
    }

    h2 {
        color: $page-color-0;
    }
}

@media (max-width: 768px) {

    button,
    .button {
        font-size: 12px;
    }
}

/* ----------------------------------------------------------------
	General
-----------------------------------------------------------------*/

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.columnContent {
    max-width: 800px;
}

img {
    max-width: 100%;
    height: auto;
}

.light {

    // contact Image light/dark swap
    @media (prefers-color-scheme: dark) {
        & {
            display: none;
        }
    }
}

.dark {
    @media (prefers-color-scheme: light) {
        & {
            display: none;
        }
    }
}

.responsive {
    width: 100%;
}

.nopadding {
    padding: 0 !important;
}

.icon {
    max-width: 100px;
    height: auto;
}

//get rid of padding top on rows that are in a form
form .row {
    padding-top: 0 !important;
}

/* Trennstreifen */

.streifen {
    background-color: $page-color-0;

    @media (prefers-color-scheme: dark) {
        & {
            background-color: $page-color-0-dark;
        }
    }

    background-image: url('../images/streifen.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 50px;
    width: 100%;
}

.store-links {
    display: flex;
    justify-content: center;
}

/* referenzen */

.referenz-1 {
    width: 130px;
    margin-bottom: 20px;
}

.referenz-1-1 {
    width: 130px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.referenz-2 {
    width: 130px;
    margin-bottom: 20px;
}

.referenz-2-1 {
    width: 130px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.referenz-3 {
    width: 130px;
    margin-bottom: 20px;
}

.referenz-3-1 {
    width: 130px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.referenz-logo {
    margin-bottom: 3rem;
}

.referenz-sameheight {
    height: 4rem;
    width: auto;
    margin-left: 2rem;
}

.referenz-demo {
    line-height: 120%;
    font-weight: 600;
    font-family: Exo, Arial, Helvetica, sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    padding: 1.25rem 1rem 1rem 1rem;
    background: linear-gradient(90deg, #95c11f 0, #029dbf);
    display: flex;
    border-radius: .5rem;

    &:focus {
        color: #fff;
    }

    &:before {
        content: "";
        background-image: url('../../images/referenzen/demo-de.svg');
        width: 2rem;
        background-repeat: no-repeat;
        margin-top: -.25rem;
        margin-right: .5rem;
        margin-left: .25rem;
    }
}

/* ----------------------------------------------------------------
	Sections
-----------------------------------------------------------------*/

section {
    padding: 100px 0;
    background-color: $page-color-0;

    @media (prefers-color-scheme: dark) {
        & {
            background-color: $page-color-0-dark;
        }
    }

    &.features {
        background-color: $page-color-7;
        color: $page-color-0;
    }

    .center {
        text-align: center;
        margin: 0 auto;
        max-width: 800px;

        .gradientBg {
            margin: 0 auto;

            span {
                text-align: center;
            }
        }
    }
}

// Teaser Banner on index-de

.teaser {
    background: linear-gradient(to right, rgba(149, 193, 31, 1) 0%, rgba(2, 157, 191, 1) 100%);
    color: #fff;
    text-align: center;
}


/* optische Trenner */

%separator {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;

    h2,
    h3 {
        color: $page-color-0;
    }

    .overlay {
        background: rgba(0, 0, 0, .7);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        min-height: 360px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .container {
            max-width: 100%;

            @media screen and (min-width: 768px) {
                max-width: 100%;
            }

            @media screen and (min-width: 1024px) {
                max-width: 970px;
            }
        }
    }
}

.separator {
    background: url('../../images/allgemein/referenzen.jpg') no-repeat center;
    background-size: cover;
    @extend %separator;

    form[action*="hsforms.com"] {
        margin: 0;

        label {
            margin: 0;
            text-align: left;
        }

        select.hs-input {
            width: 100%;
        }

        .hs_salutation {
            float: left;
            width: 25%;
        }

        .hs_lastname {
            position: relative;
            margin-left: 30%;
        }

        input[name="lastname"] {
            width: 100%;
        }

        .hs_email {
            margin-top: 2rem;
            float: none;
            clear: both;
        }

        .hs-error-msg,
        .hs-error-msgs li label {
            color: white;
            background-color: darkred;
            border-radius: 4px;
            display: inline-block;
            text-align: center;
            padding: .25rem 1rem;
        }

        .no-list {
            list-style: none;
        }

        .legal-consent-container {
            font-size: 1.2rem;
            width: 80%;
            margin: 2rem auto;
        }

        input[type="submit"] {
            padding: 0 1rem;
            margin: 2rem auto;
            background: transparent;
            border-width: 3px;
            border-style: solid;
            border-color: $page-color-2; // fix for safari
            -moz-border-image: -moz-linear-gradient(top left, #95c11f 0%, #029dbf 100%);
            -webkit-border-image: -webkit-linear-gradient(top left, right top, #95c11f, #029dbf);
            border-image: linear-gradient(to bottom right, #95c11f 0%, #029dbf 100%);
            -webkit-border-image-slice: 1;
            border-image-slice: 1;
            color: $page-color-7;
            outline: none;

            @media (prefers-color-scheme: dark) {
                & {
                    color: $page-color-7-dark;
                }
            }

            height: 2.75em;
            font-family: $page-font-family;
            font-size: math.round($page-font-size * 0.8);
        }
    }
}

//index – Newsletter Anmeldung
// jetzt neu per hubspot
.separator-2 {
    background: url('../../images/allgemein/fast-geschafft-2.jpg') no-repeat center;
    background-size: cover;
    @extend %separator;

    .overlay {
        height: 650px;

        .text {
            top: 50%;
        }
    }

}

//kontakt – mobio Partner werden
.separator-3 {
    background: url('../../images/allgemein/partner.jpg') no-repeat center;
    background-size: cover;
    @extend %separator;
}

/* ----------------------------------------------------------------
	Aside boxen
-----------------------------------------------------------------*/

/* Referenzbox */

.aside-container {
    margin-top: 2rem;
    margin-bottom: 1rem;

    h5,
    h6,
    p {
        font-family: $page-font-family-secondary;
        font-size: $page-font-size;
        line-height: 1.4;
        font-weight: $page-font-weight-light;
    }

    h5 {
        background: $page-color-2;
        background: linear-gradient(to right, rgba(149, 193, 31, 1) 0%, rgba(2, 157, 191, 1) 100%);
        display: block;
        padding: 0.8rem;
        margin-bottom: 0.3rem;
        color: $page-color-0;
        font-weight: $page-font-weight-bold;
        letter-spacing: 1;
    }

    p,
    a {
        font-size: math.round($page-font-size * 0.9);
        background-color: $page-color-7;

        @media (prefers-color-scheme: dark) {
            & {
                background-color: $page-color-9-dark;
            }
        }

        display: block;
        padding: 0.8rem;
        margin-bottom: 0.3rem;
        color: $page-color-0;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-1-dark;
            }
        }

        text-decoration: none;

        &.warning {
            background-color: $page-color-0;
            color: #f55656;

            @media (prefers-color-scheme: dark) {
                & {
                    background-color: $page-color-0-dark;
                }
            }
        }
    }

    a {
        padding: 0;
        margin: 0;
        font-family: $page-font-family-secondary;
        font-size: $page-font-size;
        line-height: 1.4;
    }
}

/* Kontakt-, Partnerboxen */

@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.025);
        -ms-transform: scale(1.025);
        transform: scale(1.025);
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.025);
        -ms-transform: scale(1.025);
        transform: scale(1.025);
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

.contactbox {
    margin: 1.5rem 0;

    .company {
        img {
            margin: 0 auto 25px;
            display: block;
            width: 150px;
            max-width: 150px;
            border-radius: 50%;

            &:hover {
                -webkit-animation: scale 1s ease-in-out;
                animation: scale 1s ease-in-out;
            }
        }
    }

    p {
        &.mobiotitle {
            text-align: center;
            text-transform: uppercase;
            padding: 0;
            font-size: math.round($page-font-size * 0.7);
        }

        &.person {
            text-align: center;
            text-transform: inherit;
            line-height: 3rem;
            font-size: 2.2rem;
        }
    }

    aside {
        border: 2px solid $page-color-6;

        @media (prefers-color-scheme: dark) {
            & {
                border: 2px solid $page-color-6-dark;
            }
        }

        &:hover {
            -webkit-animation: scale 1s ease-in-out;
            animation: scale 1s ease-in-out;
        }

        img {
            max-width: 200px;
            display: block;
            margin: 0 auto;

            // contact Image light/dark swap
            &.light {
                @media (prefers-color-scheme: dark) {
                    & {
                        display: none;
                    }
                }
            }

            &.dark {
                @media (prefers-color-scheme: light) {
                    & {
                        display: none;
                    }
                }
            }
        }

        p {
            &.mobiotitle {
                margin-bottom: 2rem;
            }
        }

        +aside {
            border: none;

            &:hover {
                -webkit-animation: none;
                animation: none;
            }

            &.aside-container {
                margin: 2rem 0;
            }
        }
    }
}

aside {
    &.aside-container {
        margin: 4rem 0;
    }
}

/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/

.hero-overlay {
    background: rgba(0, 0, 0, .4);

    @media (prefers-color-scheme: dark) {
        & {
            background: rgba(0, 0, 0, .65);
        }
    }

    opacity: 1;
    height: 75vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &:not(.index) {
        height: 60vh;
    }
}

.hero-image {
    margin-bottom: 70px;
    margin-bottom: 0;
}

.main-message {
    position: absolute;
    text-align: center;
    top: 40%;
    transform: translatey(-50%);
    -o-transform: translatey(-50%);
    -webkit-transform: translatey(-50%);
    -moz-transform: translatey(-50%);
    -ms-transform: translatey(-50%);
    width: 100%;

    h1 {
        font-family: $page-font-family;
        font-weight: 400; //font-size: 80px;
        color: #fff;
        margin: 0;
    }

    h2 {
        margin: 0 auto;

        @media (prefers-color-scheme: dark) {
            & {
                font-weight: $page-font-weight-light;
            }
        }
    }

    .gradientBg {
        span {
            @media (prefers-color-scheme: dark) {
                & {
                    color: $page-color-1-dark;
                }
            }
        }
    }

    p {
        font-size: 1.8rem;
        font-family: $page-font-family-secondary;
        color: white;
        padding-top: 2rem;
        width: 60%;
        text-align: center;
        margin: auto;

        a {
            color: $page-color-0;
            font-weight: $page-font-weight-regular;
        }
    }
}

@media (max-width: 768px) {
    .main-message {
        p {
            font-size: 1.3rem;
            line-height: 1.6;
        }
    }
}

@media (max-width: 450px) {

    .hero-overlay,
    .hero-overlay:not(.index) {
        height: 100vh;
    }

    .main-message {
        top: 50%;

        p {
            width: 90%;
        }
    }
}

/* ----------------------------------------------------------------
	Video
-----------------------------------------------------------------*/

video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%); //background: url(../../../images/video-screen-2.jpg) no-repeat;
    //background-size: cover;
    background-color: #000;
}

/* ----------------------------------------------------------------
	Grid
-----------------------------------------------------------------*/

.row {
    //   margin-right: -15px;
    //   margin-left: -15px;
    margin-right: 0;
    margin-left: 0;

    +.row {
        padding-top: 100px;
    }
}

.nopadding-left {
    padding-left: 0;
}

.nopadding-right {
    padding-right: 0;
}

@media (max-width: 768px) {

    //@media (max-width: 450px) {
    .nopadding-left,
    .nopadding-right {
        padding-right: 0;
        padding-left: 0;
    }
}

/* ----------------------------------------------------------------
	Logos
-----------------------------------------------------------------*/

.branding {
    width: 100px;
}

#indexlogo {
    // width: 400px;
    width: 350px;
}

/* ----------------------------------------------------------------
	Referenzen boxes
-----------------------------------------------------------------*/

.cardbox {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 0;
    flex-wrap: wrap;
    justify-content: center;

    .card {
        margin: $page-gutter-width-xs;
        overflow: hidden;

        a {
            display: flex;
            align-content: center;
            justify-content: space-between;
            height: 100%;
            overflow: hidden;

            picture {
                display: flex;
                align-content: center;
                justify-content: space-between;
                flex-direction: column;
                flex-wrap: nowrap;
            }
        }

        picture img {
            margin: 1rem;
            width: calc(100% - 2rem) !important;
        }

        &.bega {
            background-color: rgb(0, 0, 0);
            height: 350px;
            width: calc(39% - 3.125%);

            img {
                width: 75%;
                width: 100%;
                align-items: flex-end;
            }
        }

        &.pm {
            //background-color: rgb(25, 64, 105);
            background-color: #fff;

            @media (prefers-color-scheme: light) {
                background-color: rgb(235, 235, 235);
            }

            height: 400px;
            width: calc(33% - 3.125%);

            img {
                width: 75%;
                width: 100%;
            }
        }

        &.ff {
            background-color: rgb(112, 182, 44);
            height: 350px;
            width: calc(39% - 3.125%);

            img {
                width: 100%;
            }
        }

        &.one {
            background-color: #002d48;
            height: 400px;
            width: calc(33% - 3.125%);

            picture {
                justify-content: flex-end;
                flex-direction: column;
            }

            img {
                width: 100%;
                margin-top: 3rem;
            }
        }

        &.knows {
            background-color: rgb(90, 104, 113);
            height: 350px;
            width: calc(59% - 3.125%);

            img {
                width: 75%;
                width: 90%;
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }
        }

        &.dae {
            background: linear-gradient(#2f4791, #006fa2);
            height: 400px;
            width: calc(59% - 3.125%);

            img {
                width: 75%;
                width: 90%;
                margin-top: 3rem;
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }
        }

        &.di {
            background-color: #334d97;
            height: 400px;
            width: calc(39% - 3.125%);

            picture {
                justify-content: flex-end;
                flex-direction: column;
            }

            img {
                width: 75%;
                width: 90%;
                margin-top: 3rem;
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }
        }

        &.lmg {
            background-color: rgb(191, 56, 58);
            width: calc(59% - 3.125%);
            height: 350px;

            img {
                width: 75%;
                width: 100%;
            }
        }

        &.kkh {
            background-color: rgb(255, 255, 255);

            @media (prefers-color-scheme: light) {
                background-color: rgb(235, 235, 235);
            }

            width: calc(59% - 3.125%);
            height: 400px;

            img {
                width: 75%;
                width: 100%;
            }
        }


        &.klinik {
            background: linear-gradient(90deg, rgba(19, 234, 219, 1) 0%, rgba(21, 179, 255, 1) 100%);
            // background-color: red;
            width: calc(39% - 3.125%);
            height: 400px;

            img {
                width: 100%;
                margin-top: 3.5rem;
            }
        }

        &.spektrum {
            background-color: #000;
            height: 400px;
            width: calc(34% - 3.125%);

            picture {
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: column;
            }

            img {
                width: 75%;
                width: 200% !important;
                margin-top: 3rem;
                margin-top: 5rem;
                margin-left: -5rem;
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }
        }

        &.bfs {
            background-color: #7d0000;
            height: 400px;
            width: calc(33% - 3.125%);

            img {
                margin-top: 3rem;
                width: calc(100% - 3.125%);
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }
        }

        &.cm {
            background-color: #da002a;
            height: 400px;
            width: calc(33% - 3.125%);

            img {
                margin-top: 3rem;
                width: calc(100% - 3.125%);
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }
        }

        &.steag {
            background-color: #00386c;
            height: 400px;
            width: calc(33% - 3.125%);


            img {
                margin-top: 3rem;
                width: calc(100% - 3.125%);
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }

            // .ref-logo {
            //     max-width: 20%;

            //     img {
            //         // width: 60%;
            //     }
            // }

            // picture {
            //     justify-content: flex-start;
            //     flex-direction: column;
            //     margin-top: 3rem;
            // }

            // img {
            //     object-fit: contain;
            //     width: 100%;
            //     margin: 1rem;
            // }
        }

        &.knorr {
            background-color: #00457e;
            height: 400px;
            width: calc(34% - 3.125%);

            img {
                margin-top: 3rem;
                width: calc(100% - 3.125%);
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }

            // picture {
            //     justify-content: flex-start;
            //     flex-direction: column;
            //     margin-top: 3rem;
            // }

            // img {
            //     object-fit: contain;
            //     width: 100%;
            //     margin: 1rem;
            // }
        }

        &.iw {
            background-color: #3f3f3f;
            height: 400px;
            width: calc(33% - 3.125%);

            img {
                margin-top: 3rem;
                width: calc(100% - 3.125%);
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }
        }

        &.dw {
            background-color: #26955c;
            height: 400px;
            width: calc(34% - 3.125%);

            img {
                margin-top: 3rem;
                width: calc(100% - 3.125%);
            }

            .ref-logo {
                img {
                    align-items: flex-end;
                }
            }
        }

        .ref-logo {
            max-width: 30%;
            min-width: 8rem;
            margin: 4rem;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;

            img {
                margin: 1rem;
            }
        }
    }
}

/* ----------------------------------------------------------------
    Tablet Reference Tiles
----------------------------------------------------------------*/

@media (max-width: 1024px) {
    .cardbox {
        .card {
            .ref-logo {
                min-width: 6rem;
                margin: 2rem;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            margin: $page-gutter-width-xs;

            &.kkh {
                height: 300px;
                width: calc(49% - 3.125%);

                a {
                    flex-direction: column;

                    picture {
                        justify-content: flex-end;
                        flex-direction: column;
                    }
                }
            }

            &.bega {
                height: 300px;
                width: calc(33% - 3.125%);

                picture {
                    justify-content: flex-end;
                    flex-direction: column;
                }
            }

            &.pm {
                height: 300px;
                width: calc(59% - 3.125%);

                a {
                    flex-direction: column;

                    picture {
                        justify-content: flex-start;
                        flex-direction: column;
                    }
                }
            }

            &.lmg {
                height: 300px;
                width: calc(33% - 3.125%);

                a {
                    flex-direction: column;

                    picture {
                        justify-content: center;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-right: 0;
                            margin-bottom: 0;
                            margin-left: 0;
                        }
                    }
                }
            }

            &.ff {
                height: 300px;
                width: calc(34% - 3.125%);

                a {
                    flex-direction: column;

                    picture {
                        justify-content: center;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-right: 0;
                            margin-bottom: 0;
                            margin-left: 0;
                        }
                    }
                }
            }

            &.knows {
                height: 300px;
                width: calc(39% - 3.125%);

                a {

                    flex-direction: column;

                    picture {
                        justify-content: flex-start;
                        flex-direction: column;
                    }
                }
            }

            &.bfs {
                height: 300px;
                width: calc(33% - 3.125%);

                picture {
                    justify-content: flex-start;
                    flex-direction: column;
                }
            }

            &.dae {
                height: 300px;
                width: calc(59% - 3.125%);

                a {
                    flex-direction: column;

                    picture {
                        justify-content: flex-end;
                        flex-direction: column;
                    }
                }
            }

            &.di {
                height: 300px;
                width: calc(39% - 3.125%);

                a {

                    flex-direction: column;

                    picture {
                        justify-content: flex-end;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-top: 0;
                        }
                    }
                }
            }

            &.spektrum {
                height: 300px;
                width: calc(33% - 3.125%);

                a {
                    flex-direction: column;
                }

                picture {
                    justify-content: flex-end;
                    flex-direction: initial;
                    margin-left: 1rem;
                    margin-right: 1rem;

                    img {
                        object-fit: cover;
                        object-position: top left;
                        margin: 0;
                        max-width: 100%;
                    }
                }
            }

            &.one {
                height: 300px;
                width: calc(33% - 3.125%);

                a {
                    flex-direction: column;

                    picture {
                        justify-content: flex-end;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-top: 0;
                        }
                    }
                }
            }

            &.iw {
                height: 300px;
                width: calc(39% - 3.125%);

                picture {
                    justify-content: flex-end;
                    flex-direction: initial;

                    img {
                        object-fit: cover;
                        object-position: top left;
                        margin-right: 0;
                    }
                }
            }

            &.dw {
                height: 300px;
                width: calc(59% - 3.125%);

                picture {
                    justify-content: flex-end;
                    flex-direction: initial;

                    img {
                        object-fit: cover;
                        object-position: top left;
                        margin-bottom: 0;
                        margin-right: 0;
                    }
                }
            }

            &.knorr {
                height: 300px;
                width: calc(39% - 3.125%);

                picture {
                    justify-content: flex-end;
                    flex-direction: initial;

                    img {
                        object-fit: cover;
                        object-position: top left;
                        margin: 1rem 0 0 0;
                    }
                }
            }

            &.steag {
                height: 300px;
                width: calc(59% - 3.125%);

                picture {
                    justify-content: flex-end;
                    flex-direction: initial;

                    img {
                        object-fit: cover;
                        object-position: top left;
                        margin: 1rem 0 .5rem 0;
                    }
                }
            }

            &.klinik {
                height: 300px;
                width: calc(49% - 3.125%);

                picture {
                    justify-content: flex-end;
                    flex-direction: initial;

                    img {
                        object-fit: cover;
                        object-position: top left;
                        margin: 2rem 0 1rem 0;
                    }
                }
            }
        }
    }
}

/* ----------------------------------------------------------------
    Smartphone Reference Tiles
----------------------------------------------------------------*/

@media (max-width: 640px) {
    .cardbox {
        .card {
            margin: 0;


            &.kkh {
                height: 200px;
                width: 100%;

                a {
                    flex-direction: row;

                    picture {
                        justify-content: space-between;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.bega {
                height: 200px;
                width: 100%;

                picture {
                    justify-content: space-between;
                    flex-direction: initial;

                    img {
                        object-fit: cover;
                        object-position: top left;
                        margin-bottom: 0;
                    }
                }
            }

            &.pm {
                height: 200px;
                width: 100%;

                a {
                    flex-direction: row;

                    picture {
                        justify-content: space-between;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.lmg {
                height: 200px;
                width: 100%;

                a {
                    flex-direction: row;

                    picture {
                        justify-content: space-between;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.ff {
                height: 200px;
                width: 100%;

                a {
                    flex-direction: row;

                    picture {
                        justify-content: space-between;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.knows {
                height: 200px;
                width: 100%;

                a {
                    flex-direction: row;

                    picture {
                        justify-content: space-between;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.bfs {
                height: 200px;
                width: 100%;

                picture {
                    flex-direction: initial;

                    img {
                        object-position: top left;
                        margin-top: 1rem;
                        object-fit: cover;
                    }
                }
            }

            &.dae {
                height: 200px;
                width: 100%;

                a {
                    flex-direction: row;

                    picture {
                        flex-direction: initial;

                        img {
                            object-position: top left;
                            margin-top: 1rem;
                            object-fit: cover;
                        }
                    }
                }
            }

            &.di {
                height: 200px;
                width: 100%;

                a {
                    flex-direction: initial;

                    picture {
                        justify-content: space-between;
                        flex-direction: initial;

                        img {
                            object-fit: cover;
                            object-position: top left;
                            margin-top: 1rem;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.spektrum {
                height: 200px;
                width: 100%;

                a {
                    display: flex;
                    align-content: center;
                    justify-content: space-between;
                    height: 100%;
                    overflow: hidden;
                    flex-direction: initial;

                    picture {
                        flex-direction: initial;
                        width: 80%;
                        margin-left: 0;
                        margin-right: 0;
                        align-items: normal;

                        img {
                            object-position: top left;
                            margin-top: 1rem;
                            object-fit: cover;
                        }
                    }
                }
            }

            &.one {
                height: 200px;
                width: 100%;

                a {
                    flex-direction: row;

                    picture {
                        flex-direction: initial;
                        width: 80%;

                        img {
                            object-position: top left;
                            margin-top: 1rem;
                            object-fit: cover;
                        }
                    }
                }
            }

            &.iw {
                height: 200px;
                width: 100%;

                img {
                    margin-top: 1rem;
                }
            }

            &.dw {
                height: 200px;
                width: 100%;

                img {
                    margin-top: 1rem;
                }
            }

            &.cm {
                height: 200px;
                width: 100%;
            }

            &.knorr {
                height: 200px;
                width: 100%;

                picture {
                    margin-top: 0;
                }
            }

            &.steag {

                .ref-logo {
                    max-width: 30%
                }

                height: 200px;
                width: 100%;

                picture {
                    img {
                        margin-top: 1rem;
                        margin-bottom: 0;
                    }
                }
            }

            &.klinik {
                height: 200px;
                width: 100%;

                picture {
                    img {
                        margin-top: 1rem;
                        margin-bottom: 0;
                    }
                }
            }

            .ref-logo {
                margin: 2rem;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            picture {
                width: 100%;

                img {
                    margin: 1rem 0 0 0;
                    width: calc(100% - .5rem) !important;
                }
            }
        }
    }
}

/* ----------------------------------------------------------------
	Testimonials
-----------------------------------------------------------------*/

.testimonials {

    button[data-controls="prev"],
    button[data-controls="next"],
    .tns-controls button[data-controls=prev],
    .tns-controls button[data-controls=next] {
        left: 10% !important;

        //visibility: hidden;
        &:after {
            // visibility: visible;
            content: '';
            position: absolute;
            width: 3.75rem;
            height: 3.75rem;
            border-bottom: 2px solid #029dbf;
            border-left: 2px solid #029dbf;
            top: 50%;
            left: 75%;
            margin: -1.875rem;
            transform: rotate(45deg);
            transition: all .3s ease-out;
        }
    }

    button[data-controls="next"],
    .tns-controls button[data-controls=next] {
        right: 10% !important;
        left: auto !important;

        &:after {
            left: auto;
            right: 75%;
            margin: -1.875rem;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -ms-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }
    }

    .fa {
        &::before {
            color: $page-color-2;
            background: linear-gradient(to right, #95c11f 0%, #029dbf 100%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
        }
    }

    .overlay {
        position: relative;
    }

    .container {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .tns-nav {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 0;
        line-height: 0;
        margin: 2.5rem 0;
        text-align: center;
        white-space: nowrap;
        width: 100%;

        button.tns-nav-active {
            background-color: $page-color-5;

            @media (prefers-color-scheme: dark) {
                & {
                    background-color: $page-color-5-dark;
                }
            }
        }

        button {
            background-color: $page-color-6;

            @media (prefers-color-scheme: dark) {
                & {
                    background-color: $page-color-6-dark;
                }
            }

            display: inline-block;
            height: 1rem;
            margin: 0 .25rem;
            width: 1rem;
            border: none;
            border-radius: 50%;
            -webkit-appearance: none;
            appearance: none;
            padding: 0;
        }
    }

    .testimonials-slide {
        margin: 0 auto;

        .item {
            min-height: 320px;

            blockquote {
                font-size: math.round($page-font-size * 1.5);
                line-height: 1.6;
                margin: .75rem;
                font-weight: $page-font-weight-light;
                font-style: normal;
                color: $page-color-7;

                @media (prefers-color-scheme: dark) {
                    & {
                        color: $page-color-7-dark;
                    }
                }

                font-family: $page-font-family-secondary;
            }

            .company {
                img {
                    margin: 0 auto 25px;
                    display: block;
                    width: 150px;
                    max-width: 150px;
                    border-radius: 50%;
                }
            }

            .author {
                // font-size: math.round($page-font-size * 0.7);
                font-weight: $page-font-weight-regular;
            }
        }
    }

    &.separator {

        button[data-controls="prev"],
        button[data-controls="next"],
        .tns-controls button[data-controls=prev],
        .tns-controls button[data-controls=next] {
            &:after {
                border-bottom: 2px solid #fff;
                border-left: 2px solid #fff;
            }
        }

        .overlay {
            height: auto;
            padding: 2rem 0;

            .testimonials-slide .item blockquote {
                color: $page-color-0;

                @media (prefers-color-scheme: dark) {
                    & {
                        color: $page-color-7-dark;
                    }
                }

                font-size: math.round($page-font-size * 1.5);
                //  font-size: 2rem;
            }
        }
    }
}

@media (max-width: 1450px) {

    .testimonials button[data-controls=prev],
    .testimonials button[data-controls=next],
    .testimonials .tns-controls button[data-controls=prev],
    .testimonials .tns-controls button[data-controls=next] {
        left: 3rem !important;
    }

    .testimonials button[data-controls="next"],
    .testimonials .tns-controls button[data-controls="next"] {
        right: 3rem !important;
        left: auto !important;
    }
}

@media (max-width: 1024px) {

    .testimonials .testimonials-slide .item blockquote,
    .testimonials.separator .overlay .testimonials-slide .item blockquote {
        font-size: 2rem;
    }

    .testimonials .testimonials-slide .item .author {
        font-size: 2rem;
        line-height: 1.25;
    }
}

@media (max-width: 414px) {

    .testimonials .testimonials-slide .item blockquote,
    .testimonials.separator .overlay .testimonials-slide .item blockquote {
        font-size: 1.75rem;
    }

    .testimonials .testimonials-slide .item .author {
        font-size: 1.75rem;
        line-height: 1.25;
    }
}

/* ----------------------------------------------------------------
	App Screenshot Gallery
-----------------------------------------------------------------*/

.matches {
    padding: 0 0 50px;

    aside {
        h4 {
            line-height: 1.43;
            margin: 0 0 .425rem;
        }

        nav {
            overflow: hidden;
            position: relative;
            width: calc(100% + #{$page-gutter-width-xs});

            @for $i from 1 through 10 {
                &[data-slide-num="#{$i}"] {
                    ul {
                        transform: translateX(#{($i - 1) * (-100%)});
                    }

                    .position {
                        span:nth-of-type(#{$i}) {
                            background-color: $page-color-5;

                            @media (prefers-color-scheme: dark) {
                                & {
                                    background-color: $page-color-5-dark;
                                }
                            }
                        }
                    }
                }
            }

            h4 {
                padding-left: .5rem;
            }
        }

        ul {
            font-size: 0;
            line-height: 1;
            list-style-type: none;
            margin: 0;
            padding: 0;
            position: relative;
            white-space: nowrap;
            transition: transform .5s ease-in-out;
            will-change: transform;

            li {
                display: inline-block;
                margin: 0;
                padding: 0 .5rem;
                position: relative;
                width: 25%;

                a {
                    // background-color: #eee;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    display: block;
                    padding-bottom: 100%;
                    width: 100%;
                    height: 400px; // fix for smartphone screenhost height
                }
            }
        }

        ul.list {
            margin: 1rem 0;
            padding: 0 0 0 1rem;

            li {
                color: $page-color-1;

                @media (prefers-color-scheme: dark) {
                    & {
                        color: $page-color-1-dark;
                    }
                }

                font-size: .875rem;
                padding: .3rem 0;
                line-height: 1.6;
                margin: 0;
                list-style-type: disc;
                cursor: default;
                height: auto;
            }
        }

        .position {
            font-size: 0;
            line-height: 0;
            margin: 2.5rem 0 0;
            text-align: center;
            white-space: nowrap;
            width: 100%;

            span {
                background-color: $page-color-6;

                @media (prefers-color-scheme: dark) {
                    & {
                        background-color: $page-color-6-dark;
                    }
                }

                display: inline-block;
                height: 1rem;
                margin: 0 .25rem;
                width: 1rem;
                border-radius: 50%;
            }
        }
    }
}

@media (max-width: 768px) {
    .matches {
        aside {
            h4 {
                font-size: 1.3125rem;
            }

            ul {
                li {
                    width: 50%;
                }
            }
        }
    }
}

/* ----------------------------------------------------------------
	Footer
-----------------------------------------------------------------*/

#footer {
    margin-bottom: 0;
    background-color: #222;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-0-dark;
            background-color: $page-color-0-dark;
        }
    }

    padding-top: 50px;

    p {
        font-weight: 400;
    }

    a {
        color: $page-color-3;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-3-dark;
            }
        }
    }
}

#footer-sitemap {
    //border-bottom: 4px solid $page-color-7;
    padding: 30px 0;
    background-color: $page-color-7;

    @media (prefers-color-scheme: dark) {
        & {
            background-color: $page-color-9-dark;
        }
    }

    color: $page-color-6;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-7-dark;
        }
    }

    ul {
        margin: 20px 0px;

        li {
            list-style-type: none;
            font-family: $page-font-family-secondary;

            &.strong {
                font-weight: 600;
            }

            a {
                color: $page-color-3;

                @media (prefers-color-scheme: dark) {
                    & {
                        color: $page-color-7-dark;
                    }
                }
            }
        }
    }

    p {
        padding: 0;
        margin: 0;
        color: $page-color-3;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-7-dark;
            }
        }
    }

    @media screen and (max-width:991px) {
        div.clearfix-responsive {
            clear: both;
            float: none;
        }
    }
}

.footer-section {
    //margin-bottom: 30px;
    margin-bottom: 50px;

    a {
        p {
            display: inline;
        }
    }

    a[href="#top"] {
        text-align: center;
        display: block;
        font-size: 30px;
    }
}

img.logo-footer {
    margin-top: -15px;
    max-width: 50px;
}

p.copyright {
    // float: left;
    padding-top: 0;
    font-family: $page-font-family-secondary;
}

.social {
    font-family: $page-font-family-secondary;
    float: right;
    margin-right: -15px;

    a {
        padding-right: 15px;
    }
}

@media (max-width: 768px) {
    .footer-section {
        margin-bottom: 20px;

        a {
            display: block;
            text-align: center;

            p {
                font-size: 1.5rem
            }
        }

        a[href="#top"] {
            text-align: inherit;
            display: inherit;

            i {
                text-align: center;
                width: 100%;
            }
        }
    }

    #footer-sitemap,
    p.copyright,
    .social a {
        text-align: center;
    }

    .social.copyright {
        padding-bottom: 1rem;
        float: none;
        margin-right: 0;

        a {
            width: 100%;
            display: block;
            padding: 0;
        }
    }
}

.mehr-info-video {
    //background: url('../../images/photo-1454165804606-c3d57bc86b40-small.jpg');
    background-image: url('../../images/referenzen.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;

    h2 {
        font-family: Exo, Arial, Helvetica, sans-serif;
    }

    h3 {
        color: #fff;
        font-family: Exo, Arial, Helvetica, sans-serif;
    }

    .overlay {
        background: rgba(0, 0, 0, .7);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 360px;
    }

    .text {
        position: absolute;
        text-align: center;
        top: 48%;
        transform: translatey(-50%);
        -o-transform: translatey(-50%);
        -webkit-transform: translatey(-50%);
        -moz-transform: translatey(-50%);
        -ms-transform: translatey(-50%);
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

/*
.mehr-info-video-2 {
    background: url('../../images/photo-1474403078171-7f199e9d1335.jpg');
    background: url('../../images/knows-livekachel.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;
    h2 {
        font-family: Exo, Arial, Helvetica, sans-serif;
    }
    h3 {
        color: #fff;
        font-family: Exo, Arial, Helvetica, sans-serif;
    }
    .overlay {
        background: rgba(0, 0, 0, .7);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 360px;
    }
    .text {
        position: absolute;
        text-align: center;
        top: 48%;
        transform: translatey(-50%);
        -o-transform: translatey(-50%);
        -webkit-transform: translatey(-50%);
        -moz-transform: translatey(-50%);
        -ms-transform: translatey(-50%);
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}


/* ----------toggle ---------- */

/*
.toggle-icon {
    visibility: hidden;
}

.toggle-box {
    width: 200px;
    height: 0;
    transition: all 0.4s linear;
    visibility: hidden;
}

input:checked~.toggle-box {
    height: 220px;
    visibility: visible;
    transition: all 0.4s linear;
}

label {
    color: black;
}

input:checked~.toggle-icon {
    visibility: visible;
}


/* fixes */

/*
.overlay {
    h2 {
        font-weight: 400;
    }
}

#strategie {
    background-image: url('../../images/strategie/strategie.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.vertical-align-transform {
    position: relative;
    //transform: translate3d(0, -50%, 0);
    //transform: translate3d(0, 50%, 0);
    //-webkit-transform: translate3d(0, -50%, 0);
    //-webkit-transform: translate3d(0, 50%, 0);
    //top: 50%;
    z-index: 2;
    //display: flex;
    //justify-content: center;
}
.right {
    right: 0;
}

.left {
    left: 0;
}
*/

/* ----------------------------------------------------------------
	Grid
-----------------------------------------------------------------*/

.col-sm-6 {
    margin: 0 0 3rem 0;
}

.overview {
    .col-md-6 {
        padding: 0;
    }
}

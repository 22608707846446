@font-face {
    font-family: Exo;
    src: url(../../fonts/Exo-Light.woff);
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: Exo;
    src: url(../../fonts/Exo-Medium.woff);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Exo;
    src: url(../../fonts/Exo-ExtraBold.woff);
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(../../fonts/OpenSans-Light.woff);
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(../../fonts/OpenSans-LightItalic.woff);
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url(../../fonts/OpenSans-Regular.woff);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'FontAwesome';
    src: url(../../fonts/fontawesome-webfont.woff);
    font-weight: 300;
    font-style: normal;
}

/*@import url('https://fonts.googleapis.com/css?family=Exo:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');*/

// global variables

$lns-debug: true; // as: enables debugging related css rules [true|false]

// Bootstrap variables

$enable-flex: true;
$grid-breakpoints: (xs: 0, sm: 375px, md: 768px, lg: 1024px, xl: 1200px) !default; // xs: e.g. iphone 4s (< 375px, e.g. 320px), sm: iphone 6 (375px), lg: ipad (1024px)
$container-max-widths: (sm: 767px, md: 1023px, lg: 1199px, xl: 1280px) !default;
$grid-gutter-width: 0 !default;

// Dark mode colors

$page-color-0-dark: rgb(30, 34, 39) !default;
$page-color-1-dark: rgb(255, 255, 255) !default; // text color
$page-color-2-dark: rgb(151, 191, 13) !default; // green
$page-color-8-dark: rgb(2, 157, 191) !default; // highlight color
$page-color-3-dark: rgb(221, 220, 224) !default; // light gray
$page-color-4-dark: rgb(245, 255, 245) !default; // light green
$page-color-5-dark: rgb(197, 203, 206) !default; // light gray rgb(90%, 90%, 90%)
$page-color-6-dark: rgb(70, 78, 83) !default; // dark gray
//$page-color-6-dark: rgb(200, 200, 200) !default; // light gray
$page-color-7-dark: rgb(227, 232, 235) !default; //text color
$page-color-9-dark: rgb(56, 59, 63) !default; //lighter Background color


// colors

$page-color-0: white !default;
$page-color-1: black !default; // text color
$page-color-2: rgb(151, 191, 13) !default; // green
$page-color-8: rgb(2, 157, 191) !default; // highlight color
$page-color-3: rgb(148, 147, 153) !default; // dark gray
$page-color-4: rgb(245, 255, 245) !default; // light green
$page-color-5: rgb(98, 107, 113) !default; // dark gray
$page-color-6: rgb(90%, 90%, 90%) !default; // light gray
//$page-color-6: rgb(200, 200, 200) !default; // light gray
$page-color-7: rgb(68, 77, 83) !default; //darker gray


// fonts

$page-font-family: 'Open Sans', Arial, Helvetica, sans-serif !default;
$page-font-family-secondary: 'Exo', Arial, Helvetica, sans-serif !default;
$fontawesome: 'FontAwesome' !default;
$page-font-size: 18px !default;
$page-font-weight-light: 200 !default;
$page-font-weight-regular: 400 !default;
$page-font-weight-bold: 600 !default;
$page-line-height: 1.6 !default;

// Links
// Style anchor elements.
$link-color: $page-color-5 !default;
$link-decoration: none !default;
$link-hover-color: rgb(151, 191, 13) !default;
$link-hover-decoration: underline !default;


// z-index (ascending)


// assorted variables

$page-gap-x-xs: 1.15rem !default;
$page-gap-x-lg: 1.5rem !default;
$page-gap-y-xs: 1.625rem !default;
$page-gap-y-lg: 1.625rem !default;
$page-padding-x-xs: (1rem - (#{$grid-gutter-width} / 2)) !default;
$page-padding-x-sm: (1rem - (#{$grid-gutter-width} / 2)) !default;
$page-padding-x-lg: (3.375rem - (#{$grid-gutter-width} / 2)) !default;
$page-padding-y-xs: 1rem !default;
$page-padding-y-sm: 1.35rem !default;
$page-padding-y-lg: 2.25rem !default;
$icon-size-xs: 32px !default;
$icon-size-lg: 42px !default;
$circular-size-xs: 6.25rem !default;
$circular-size-lg: 13.625rem !default;


// global variables

//Headlines
$grosse-hl: 3.3rem;
$mittlere-hl: 1.8rem;
$kleine-hl: 1.5rem;
$sehr-kleine-hl: 1rem;


$page-gutter-width-xs: 1rem !default;
//$page-gutter-width-xs: 5%!default;


// media queries
//$media-width-tablet: 768px !default;
//$media-width-desktop: 1025px !default;

//$spacer: 3rem!default;



/* images  kann vermutlich weg */

#cr-1 {
    background: url('../../images/allgemein/fehler.jpg') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: auto;
}

#cr-2 {
    background: url('../../images/allgemein/fast-geschafft-2.jpg') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: auto;
}

#cr-3 {
    background: url('../../images/allgemein/geschafft-2.jpg') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: auto;
}
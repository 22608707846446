/* ----------------------------------------------------------------
	Formular
-----------------------------------------------------------------*/
@use "sass:math";

form {
    padding: 0;
    border: 0;
    font-size: 100%;
    font-style: inherit;
    vertical-align: baseline;
    margin: 0 0 2em 0;
}

form .message {
    text-decoration: none;
    transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    height: 2.75em;
    line-height: 2.75em;
    opacity: 0;
}

form .message:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: $fontawesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
    margin-right: 0.5em;
}

form .message.visible {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

form label {
    color: $page-color-0;
    display: block; // font-size: 0.9em;
    margin: 0 0 1em 0;
}

@-webkit-keyframes focus {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.025);
        -ms-transform: scale(1.025);
        transform: scale(1.025);
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes focus {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.025);
        -ms-transform: scale(1.025);
        transform: scale(1.025);
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="time"],
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition: border-color 0.2s ease, background-color 0.2s ease;
    background-color: transparent;
    border: none;
    border: solid 2px rgba(200, 200, 200, 0.35);
    color: $page-color-0;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;
    font-weight: $page-font-weight-light;
    font-size: math.round($page-font-size * 0.8);
}

input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
input[type="tel"]:invalid,
input[type="time"]:invalid,
select:invalid,
textarea:invalid {
    box-shadow: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
select:focus,
textarea:focus {
    -webkit-animation: focus 0.1s;
    animation: focus 0.1s;
    background-color: rgba(255, 255, 255, 0.125);
    border-color: $page-color-2;
    color: $page-color-0;
}

.select-wrapper {
    text-decoration: none;
    display: block;
    position: relative;
}

.select-wrapper:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'FontAwesome', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
    color: rgba(255, 255, 255, 0.35);
    content: '\f078';
    display: block;
    height: 2.75em;
    line-height: 2.75em;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 2.75em;
}

.select-wrapper select::-ms-expand {
    display: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="time"],
select {
    height: 2.75em;
}

textarea {
    padding: 0.75em 1em;
}

::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1.0;
}

:-moz-placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1.0;
}

::-moz-placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1.0;
}

:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1.0;
}

.formerize-placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1.0;
}

/* ----------------------------------------------------------------
	Signup Form CleverReach
-----------------------------------------------------------------*/

.cr_button {
    margin-left: 1rem;
}

.cr_form {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.cr_form input[type="text"],
.cr_form input[type="password"],
.cr_form input[type="email"],
.cr_form input[type="tel"],
.cr_form input[type="time"] {
    width: 30em;
}

.cr_form>* {
    margin: 0 0 0 1em;
}

.cr_form>:first-child {
    margin: 0 0 0 0;
}

@media screen and (max-width: 480px) {
    .cr_form {
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .cr_form input[type="password"],
    .cr_form input[type="email"],
    .cr_form input[type="tel"],
    .cr_form input[type="time"],
    .cr_form input[type="text"] {
        width: 100%;
    }

    .cr_form>* {
        margin: 1.25em 0 0 0;
    }

    .cr_form .message {
        bottom: -1.5em;
        font-size: 0.9em;
        height: 1em;
        left: 0;
        line-height: inherit;
        margin-top: 0;
        position: absolute;
    }

    form {
        button.cr_button {
            margin: 2rem 0 0 1rem;
        }
    }
}

/* ----------------------------------------------------------------
	Button Newsletter
-----------------------------------------------------------------*/

#newsletter-mobio {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000000;
}

.paperplane {
    a {
        color: $page-color-0;
        background-color: $page-color-8;
        font-size: 26px;
        line-height: 3.5rem;
        padding: 6px 8px 8px 4px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }

    a:hover {
        padding: 6px 8px 8px 4px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: $page-color-2;
        color: $page-color-0;
        transition-timing-function: ease-in-out;
        transition-duration: 0.2s;
        transform: scale(1.1);
    }

    a:active,
    a:visited,
    a:focus {
        color: $page-color-0 !important;
    }
}

/* ----------------------------------------------------------------
	Button Mailto
-----------------------------------------------------------------*/

#kontakt-mobio {
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 1000000;
}

.mail {
    a {
        color: $page-color-0;
        background-color: $page-color-8;
        font-size: 26px;
        line-height: 3.5rem;
        padding: 6px 4px 8px 4px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }

    a:hover {
        padding: 6px 4px 8px 4px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: $page-color-2;
        color: $page-color-0;
        transition-timing-function: ease-in-out;
        transition-duration: 0.2s;
        transform: scale(1.1);
    }

    a:active,
    a:visited,
    a:focus {
        color: $page-color-0 !important;
    }
}

/* ----------------------------------------------------------------
	Modal – Newsletter, Mailkontakt
-----------------------------------------------------------------*/

/* The Modal (background) */

.modal {
    display: none;
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */

.modal-content {
    background-image: url('../../images/streifen.png');
    background-repeat: no-repeat;
    margin: 20% auto;
    padding: 2rem 3rem;
    border: 1px solid #888;
    width: 80%;
    border-radius: 0;
    max-width: 800px;

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="time"],
    select,
    textarea,
    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="email"]:focus,
    input[type="tel"]:focus,
    input[type="time"]:focus,
    select:focus,
    textarea:focus {
        color: $page-color-5;
    }
}

/* The Close Button */

.close {
    color: $page-color-7;
    float: right;
    font-weight: $page-font-weight-bold;
    line-height: 1rem;
    font-size: 40px;
    opacity: 0.3;
}

.close:hover,
.close:focus {
    color: $page-color-7;
    text-decoration: none;
    cursor: pointer;
}

/* ----------------------------------------------------------------
	styles for custom button userlike
-----------------------------------------------------------------*/

/* styles chat tool */

//not needed anymore
/*
.myButton {
    position: fixed;
    bottom: 140px;
    right: 20px;
    z-index: 1000000;
    color: $page-color-0;
    background-color: $page-color-8;
    font-size: 26px;
    line-height: 3.5rem;
    padding: 6px 4px 8px;
    border-radius: 50%;
    width: 50px!important;
    height: 50px!important;
    i {
        padding: 0 8px;
    }
}

.myButton:hover {
    background-color: $page-color-2;
    color: $page-color-0;
    transition-timing-function: ease-in-out;
    transition: 0.2s;
    transform: scale(1.1);
}
*/

/* ----------------------------------------------------------------
	Tabs
-----------------------------------------------------------------*/


/*
.tabnavigation {
    background-color: $page-color-0;
    min-height: 500px;
    ul.tab {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        // border: 1px solid #ccc;
        background-color: #fff;
    }
    ul.tab li {
        float: left;
    }
    ul.tab li a {
        display: inline-block;
        color: black;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        transition: 0.3s;
        font-size: 17px;
    }
    ul.tab li a:hover {
        background-color: #ddd;
    }
    ul.tab li a:focus,
    .active {
        background-color: #ccc;
    }
    .tabcontent {
        display: none;
        padding: 6px 12px;
        ul {
            li {
                &:first-child {
                    list-style-type: none;
                    img {
                        max-width: 30%;
                        height: auto;
                        margin: 20px 0;
                    }
                }
                list-style-type: disc;
            }
        }
    }
    .tabcontent {
        -webkit-animation: fadeEffect 1s;
        animation: fadeEffect 1s;
    }
    @-webkit-keyframes fadeEffect {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fadeEffect {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}


/* ---------- tab --------------- */

.tabnavigation {
    input {
        visibility: hidden;
        color: $page-color-7;
    }

    label {
        border: 3px solid $page-color-6;
        color: $page-color-7;
        cursor: pointer;
        display: block;
        float: left;
        margin-right: .25rem;
        text-align: center;
        padding: 1rem 2rem;
    }

    input:hover+label {
        color: $page-color-7;
        border: 3px solid $page-color-7;
        appearance: none;
        transition: border 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }

    input:checked+label {
        background: transparent;
        border: 3px solid black; // fix for safari 
        border-image: linear-gradient(to bottom right, #95c11f 0%, #029dbf 100%);
        color: $page-color-7;
        border-image-slice: 1;
    }
}

#content {
    min-height: 30rem;
    height: auto;
    position: relative;
    width: 100%;
    z-index: 5;

    div#content-1,
    div#content-2,
    div#content-3 {
        opacity: 0;
        position: absolute;
        z-index: -100;

        img {
            max-width: 100%;
        }

        .content-1-text {
            max-width: 30%;
            float: left;
            margin-right: 8.88%;
        }

        .content-1-img {
            max-width: 60%;
            float: left;
        }
    }
}

.tabnavigation input#tab-1:checked~#content #content-1,
.tabnavigation input#tab-2:checked~#content #content-2,
.tabnavigation input#tab-3:checked~#content #content-3 {
    opacity: 1;
    z-index: 100;
}

input.visible {
    visibility: visible !important;
}


/* responsive */

@media (max-width: 1210px) {
    .tabnavigation {
        background-color: #fff;
        width: 100%;

        #container {
            max-width: 970px;
        }
    }

    #entitlement #container-2 {
        max-width: 970px;
    }
}

@media (max-width: 990px) {

    #content-1,
    #content-2,
    #content-3 {

        .content-1-img,
        .content-2-img {
            max-width: 100%;
        }

        .content-1-text,
        .content-2-text {
            max-width: 100%;
        }
    }

    #entitlement {

        #content-4,
        #content-5,
        #content-6,
        #content-7 {
            .content-2-text {
                max-width: 100%;
            }
        }
    }

    #content-1 .content-1-img,
    #content-2 .content-1-img,
    #content-3 .content-1-img,
    #content-1 .content-2-img,
    #content-group-2 .content-2-img,
    #content-3 .content-2-img {
        max-width: 60%;
    }

    #content {
        min-height: 54em;
    }

    #entitlement #content-group-2 {
        min-height: 50em;
    }

    #entitlement #content-6 .content-2-img,
    #entitlement #content-7 .content-2-img {
        max-width: 100%;
    }

    #entitlement #content-4 .content-2-img,
    #entitlement #content-5 .content-2-img {
        max-width: 60%;
    }

    .tabnavigation {
        #container {
            max-width: 800px;

            #content {
                top: 60px;
                min-height: 55em;
            }

            #content-2 {
                top: 0px;
            }
        }
    }


    #entitlement #container-2 {
        max-width: 800px;

        label {
            padding: 0 0.8em;
        }
    }

    #content-group-2 {
        top: 50px;
    }
}

@media (max-width: 768px) {
    #content {
        min-height: 40em;
    }

    #content-1 .content-1-img,
    #content-2 .content-1-img,
    #content-3 .content-1-img,
    #content-1 .content-2-img,
    #content-group-2 .content-2-img,
    #content-3 .content-2-img {
        max-width: 70%;
    }

    #entitlement #content-4 .content-2-text,
    #entitlement #content-5 .content-2-text,
    #entitlement #content-6 .content-2-text,
    #entitlement #content-7 .content-2-text {
        margin-top: 50px;
    }

    #entitlement #content-group-2 #content-4 .content-2-text,
    #entitlement #content-group-2 #content-5 .content-2-text,
    #entitlement #content-group-2 #content-6 .content-2-text,
    #entitlement #content-group-2 #content-7 .content-2-text {
        width: 100%;
        max-width: 100%;
        margin-top: 0;
        margin-right: 0;
    }

    #entitlement #content-group-2 #content-4 .content-2-img,
    #entitlement #content-group-2 #content-5 .content-2-img,
    #entitlement #content-group-2 #content-6 .content-2-img,
    #entitlement #content-group-2 #content-7 .content-2-img {
        max-width: 60%;
    }
}

@media (max-width: 750px) {
    #entitlement #container-2 {
        max-width: 690px;
    }
}

@media (max-width: 450px) {

    #entitlement #content-group-2 #content-4 .content-2-text,
    #entitlement #content-group-2 #content-5 .content-2-text,
    #entitlement #content-group-2 #content-6 .content-2-text,
    #entitlement #content-group-2 #content-7 .content-2-text {
        width: 100%;
        max-width: 100%;
        margin-top: 0;
        margin-right: 0;
    }

    #entitlement #content-group-2 #content-4 .content-2-img,
    #entitlement #content-group-2 #content-5 .content-2-img,
    #entitlement #content-group-2 #content-6 .content-2-img,
    #entitlement #content-group-2 #content-7 .content-2-img {
        max-width: 100%;
    }

    #content-1 .content-1-img,
    #content-1 .content-1-text,
    #content-1 .content-2-img,
    #content-1 .content-2-text,
    #content-2 .content-1-img,
    #content-2 .content-1-text,
    #content-group-2 .content-2-img,
    #content-group-2 .content-2-text,
    #content-3 .content-1-img,
    #content-3 .content-1-text,
    #content-3 .content-2-img,
    #content-3 .content-2-text,
    #entitlement #content-group-2 #content-4 .content-2-text,
    #entitlement #content-group-2 #content-5 .content-2-text,
    #entitlement #content-group-2 #content-6 .content-2-text,
    #entitlement #content-group-2 #content-7 .content-2-text {
        width: 100%;
        margin-top: 0;
        margin-right: 0;
    }

    #content {
        min-height: 40em;
    }

    #content-1 .content-1-img,
    #content-2 .content-1-img,
    #content-3 .content-1-img,
    #content-1 .content-2-img,
    #content-group-2 .content-2-img,
    #content-3 .content-2-img {
        max-width: 45%;
    }

    #content-group-2 {
        top: 115px;
    }

    #entitlement #content-group-2 #content-4 .content-2-img,
    #entitlement #content-group-2 #content-5 .content-2-img {
        max-width: 100%;
    }

    #entitlement #container-2 input {
        height: 2em;
    }

    #entitlement #content-group-2 {
        min-height: 54em;
    }

    #entitlement #content-group-2 #content-6 {
        max-height: 30em;
    }

    #entitlement #container-2 {
        padding-top: 0;
        max-width: 350px;

        label {
            padding: 0 0.8em;
        }
    }

    .tabnavigation #container {
        max-width: 350px;

        #content {
            min-height: 45em;
            top: 60px;
        }

        #content-2 {
            top: 0px;
        }
    }

    #content-1 .content-1-img,
    #content-1 .content-2-img,
    #content-2 .content-1-img,
    #content-2 .content-2-img,
    #content-3 .content-1-img,
    #content-3 .content-2-img {
        max-width: 100%;
    }
}
/*
- Name: megamenu.js - style.css
- Version: 1.0
- Latest update: 29.01.2016.
- Author: Mario Loncarek
- Author web site: http://marioloncarek.com
- Edited by Christina Gesse & Niels Plath
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Body - not related to megamenu
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
megamenu.js STYLE STARTS HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.nav {
    width: 100%;
    margin: 0 auto;
    background: #fff;
    z-index: 10000;
    position: fixed;

    @media (prefers-color-scheme: dark) {
        & {
            color: $page-color-7-dark;
        }
    }

    // position: -webkit-sticky;
    // position: -moz-sticky;
    // position: -ms-sticky;
    // position: -o-sticky;
    // position: sticky!important;
    // top: 15px;
    // sticky does only work when: top is set, overflow is not hidden on any parent element, parent elements dont scroll out of view
    ul li {
        font-size: 1.6rem;
    }

    .menu-topline {
        background: $page-color-6;

        @media (prefers-color-scheme: dark) {
            & {
                background: $page-color-0-dark;
            }
        }

        color: $page-color-7;

        @media (prefers-color-scheme: dark) {
            & {
                color: $page-color-7-dark;
            }
        }

        font-size: .85em;
        text-align: right;

        a {
            font-weight: 700;
            color: $page-color-2;

            @media (prefers-color-scheme: dark) {
                & {
                    color: $page-color-7-dark;
                }
                &.demo {
                    color: $page-color-2-dark;
                }
            }

            &.de {
                &:after {
                    content: '';
                    padding: 0 2rem 0 0;
                    background-size: 1.75rem;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgwIiB3aWR0aD0iNjQwIiB2ZXJzaW9uPSIxIj4KICA8ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS13aWR0aD0iMXB0Ij4KICAgIDxwYXRoIGZpbGw9IiNmYzAiIGQ9Ik0wIDMyMGg2NDB2MTYwLjAwMkgweiIvPgogICAgPHBhdGggZD0iTTAgMGg2NDB2MTYwSDB6Ii8+CiAgICA8cGF0aCBmaWxsPSJyZWQiIGQ9Ik0wIDE2MGg2NDB2MTYwSDB6Ii8+CiAgPC9nPgo8L3N2Zz4K);
                    background-position: center right;
                    background-repeat: no-repeat;
                }
            }

            &.en {
                &:after {
                    content: '';
                    padding: 0 2rem 0 0;
                    background-size: 1.75rem;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgwIiB3aWR0aD0iNjQwIj4KICA8ZGVmcz4KICAgIDxjbGlwUGF0aCBpZD0iYSI+CiAgICAgIDxwYXRoIGZpbGwtb3BhY2l0eT0iLjY3IiBkPSJNLTg1LjMzMyAwaDY4Mi42N3Y1MTJoLTY4Mi42N3oiLz4KICAgIDwvY2xpcFBhdGg+CiAgPC9kZWZzPgogIDxnIGNsaXAtcGF0aD0idXJsKCNhKSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODApIHNjYWxlKC45NCkiPgogICAgPGcgc3Ryb2tlLXdpZHRoPSIxcHQiPgogICAgICA8cGF0aCBmaWxsPSIjMDA2IiBkPSJNLTI1NiAwSDc2OC4wMnY1MTIuMDFILTI1NnoiLz4KICAgICAgPHBhdGggZD0iTS0yNTYgMHY1Ny4yNDRsOTA5LjUzNSA0NTQuNzY4SDc2OC4wMlY0NTQuNzdMLTE0MS41MTUgMEgtMjU2ek03NjguMDIgMHY1Ny4yNDNMLTE0MS41MTUgNTEyLjAxSC0yNTZ2LTU3LjI0M0w2NTMuNTM1IDBINzY4LjAyeiIgZmlsbD0iI2ZmZiIvPgogICAgICA8cGF0aCBkPSJNMTcwLjY3NSAwdjUxMi4wMWgxNzAuNjdWMGgtMTcwLjY3ek0tMjU2IDE3MC42N3YxNzAuNjdINzY4LjAyVjE3MC42N0gtMjU2eiIgZmlsbD0iI2ZmZiIvPgogICAgICA8cGF0aCBkPSJNLTI1NiAyMDQuODA0djEwMi40MDJINzY4LjAyVjIwNC44MDRILTI1NnpNMjA0LjgxIDB2NTEyLjAxaDEwMi40VjBoLTEwMi40ek0tMjU2IDUxMi4wMUw4NS4zNCAzNDEuMzRoNzYuMzI0bC0zNDEuMzQgMTcwLjY3SC0yNTZ6TS0yNTYgMEw4NS4zNCAxNzAuNjdIOS4wMTZMLTI1NiAzOC4xNjRWMHptNjA2LjM1NiAxNzAuNjdMNjkxLjY5NiAwaDc2LjMyNEw0MjYuNjggMTcwLjY3aC03Ni4zMjR6TTc2OC4wMiA1MTIuMDFMNDI2LjY4IDM0MS4zNGg3Ni4zMjRMNzY4LjAyIDQ3My44NDh2MzguMTYyeiIgZmlsbD0iI2MwMCIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==);
                    background-position: center right;
                    background-repeat: no-repeat;
                }
            }
        }

        >* {
            display: inline-block;
            margin-right: 1rem;
        }

        a i {
            font-size: 1.5em;
            position: relative;
            top: .1em;
        }
    }
}

.nav,
.menu>ul>li:hover>ul {
    border-width: 4px;
    border-style: solid;
    border-color: $page-color-2; // fix for safari
    -moz-border-image: -moz-linear-gradient(top left, #95c11f 0%, #029dbf 100%);
    -webkit-border-image: -webkit-linear-gradient(top left, right top, #95c11f, #029dbf);
    border-image: linear-gradient(to bottom right, #95c11f 0%, #029dbf 100%);
    border-image-slice: 1;
    border-left: 0; // fix for safari
    border-right: 0; // fix for safari
    border-top: 0; // fix for safari
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Screen style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.menu-container {
    padding: 0;
    margin: 0 auto;
    background: #fff;

    @media (prefers-color-scheme: dark) {
        & {
            background: $page-color-9-dark;
        }
    }
}

.menu-dropdown-icon {
    &:before {
        content: "\f067";
        font-family: "FontAwesome", sans-serif;
        display: none;
        cursor: pointer;
        float: right;
        padding: 1.5em 2em;
        background: #fff;

        @media (prefers-color-scheme: dark) {
            & {
                background: $page-color-9-dark;
            }
        }

        color: #333;
    }
}

.menu {
    div.mobile-logo {
        display: none;

        img {
            height: 65px;
        }
    }

    input#menu-toggle {
        display: none;
    }

    label[for="menu-toggle"] {
        font-size: 1.2em;
        display: none;
        color: inherit;
        margin: 2rem;
    }

    >ul {
        margin: 0 auto;
        width: 100%;
        list-style: none;
        padding: 0;
        position: relative;
        /* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        @media (prefers-color-scheme: dark) {
            & {
                background: $page-color-9-dark;
            }
        }

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }

        #menu-branding {
            padding: 0 3em 0 0;

            a {
                margin: auto 0;
            }

            img {
                height: 65px;
                width: auto;
                visibility: visible !important;

                // contact Image light/dark swap
                &.light {
                    @media (prefers-color-scheme: dark) {
                        & {
                            display: none;
                        }
                    }
                }

                &.dark {
                    @media (prefers-color-scheme: light) {
                        & {
                            display: none;
                        }
                    }
                }
            }
        }

        >li {
            float: left;

            margin: 0;
            font-family: $page-font-family-secondary;
            padding: 0 3em;

            &:hover {
                background-color: transparent;

                @media (prefers-color-scheme: dark) {
                    & {
                        background: $page-color-9-dark;
                    }
                }

                >a {
                    border-bottom-color: $page-color-3;

                    @media (prefers-color-scheme: dark) {
                        & {
                            border-bottom-color: $page-color-3-dark;
                        }
                    }

                    transition: ease-in .3s border-bottom-color;
                }

                >ul {
                    transition: ease .3s opacity;
                    opacity: 1;
                    display: block;
                }
            }

            a {
                text-decoration: none;
                border-bottom: 2px solid transparent;
                transition: ease-in .3s border-bottom-color;
                display: inline-block;
                padding: .5em 0 0 0;
                margin: 1em 0;
            }

            &#menu-branding:hover>a {
                border-bottom-color: transparent;
            }

            >ul {
                display: none;
                position: absolute;
                left: 0;
                margin: 0;
                list-style: none;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                width: 100%;
                background-color: #fff;

                @media (prefers-color-scheme: dark) {
                    & {
                        background: $page-color-9-dark;
                    }
                }

                min-height: 10rem;
                transition: ease .3s opacity;
                opacity: 0;
                padding: 20px 20px 20px calc(200px + 30%);

                &:before,
                &:after {
                    content: "";
                    display: table;
                }

                &:after {
                    clear: both;
                }

                >li {
                    list-style: none; // width: 25%;
                    background: none;
                    padding: 0;
                    padding-bottom: 0;
                    margin: 0;
                    font-family: $page-font-family-secondary;
                    float: none;
                    margin-left: calc(23% + 200px);

                    &:hover>a {
                        border-bottom-color: $page-color-3;

                        @media (prefers-color-scheme: dark) {
                            & {
                                border-bottom-color: $page-color-3-dark;
                            }
                        }

                        transition: ease-in .3s border-bottom-color;
                    }

                    >a {
                        color: #777;

                        @media (prefers-color-scheme: dark) {
                            & {
                                color: $page-color-7-dark;
                            }
                        }

                        border-bottom: 2px solid transparent;
                        transition: ease-in .3s border-bottom-color;
                        padding: 0;
                        display: inline-block;
                        width: auto;
                        margin: .8rem 0;

                        &:before {
                            content: '» ';
                        }
                    }
                }

                &.normal-sub {
                    width: 300px;
                    left: auto;
                    padding: 10px 20px;

                    >li {
                        width: 100%;

                        a {
                            border: 0;
                            padding: 1em 0;
                        }
                    }
                }

                >div {
                    width: calc(30% + 200px - 40px);
                    position: absolute;
                    top: 20px;
                    left: 23%;
                    height: calc(100% - 40px);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >* {
                        float: left;
                        margin: auto 0;
                    }

                    &:after {
                        content: ' ';
                        display: block;
                        height: 0;
                        clear: both;
                        float: none;
                    }

                    img {
                        max-width: 50%;
                        max-height: calc(100% - 4rem);
                    }

                    p {
                        padding: 0 2rem;
                        color: $page-color-3;

                        @media (prefers-color-scheme: dark) {
                            & {
                                color: $page-color-3-dark;
                            }
                        }

                        font-weight: 500;
                    }
                }
            }
        }
    }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media only screen and (max-width: 959px) {
    .menu-container {
        width: 100%;
    }

    .menu {
        div.mobile-logo {
            display: inline-block;
            position: absolute;
            left: 0; // margin: 2rem;
            margin: .5rem;

            img {
                visibility: visible !important;
            }
        }

        text-align: right;

        label[for="menu-toggle"] {
            display: inline-block;
        }

        >ul {
            width: 100vw;
            height: 100vh;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
            background: white;
            @media (prefers-color-scheme: dark) {
                & {
                    background: $page-color-9-dark;
                }
            }
            transform: translateX(300vw);
            transition: .2s ease transform;
            display: block;
            overflow-y: auto;

            @media (prefers-color-scheme: dark) {
                & {
                    background: $page-color-9-dark;
                }
            }

            label.menu-close {
                position: absolute;
                top: 0;
                right: 0;
            }

            >li {
                display: block;
                width: 100%;
                padding: 2rem;

                >a {
                    margin: .25rem 0;
                    font-weight: 500;
                }

                >ul {
                    display: block;
                    opacity: 1;
                    position: static;
                    padding: 0;
                    border: none;
                    text-align: center;

                    div:first-child {
                        display: none;
                    }

                    >li {
                        width: 100%;
                        margin-left: 0 !important;

                        // @media (prefers-color-scheme: dark) {
                        //     & {
                        //         background: $page-color-9-dark;
                        //     }
                        // }

                        >a {
                            margin: 0;
                        }

                        &:hover>a {
                            border-bottom-color: transparent;
                        }
                    }

                    &:after {
                        display: block;
                        height: 0px;
                        content: ' ';
                        clear: both;
                        float: none;
                    }
                }

                &:hover>ul {
                    border: none;
                }
            }
        }

        input#menu-toggle:checked~ul {
            transform: translateX(0vw);
            transition: .3s ease transform;
        }
    }
}

@media only screen and (max-width: 959px) {
    .menu {
        >ul {
            >li {
                &#menu-branding {
                    padding: 5rem 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .menu {
        >ul {
            >li {
                padding: 1rem;

                &#menu-branding {
                    padding: 2rem 0;
                }
            }
        }
    }
}
// global functions and mixins optimized for Bootstrap usage
/* stylelint-disable indentation */
// function for unit values to numbers convertion (e.g. 1.2em -> 1.2)
@function lns-to-number($number) {
	@return $number / ($number * 0 + 1);
}

/// mixin for webfont inclusion
///
/// @author schaffrath, Andre Schuessler
///
/// @access public
///
/// @param {String} $font-family - family name of the font, e.g. 'Ubuntu'
///
/// @param {String} $file-name - the font files for all formats have to share the same file name (excluding extension), the common part has to be supplied here
///
/// @param {String} $svgid - ID of the font within the given SVG file, e.g. 'ubunturegular'
///
/// @param {Number} $font-weight - css font-weight - has to be numeric, e.g. 400
///
/// @param {String} $font-local-weight - font-weight for local fonts, e.g. 'Regular' or 'Light'
///
/// @param {Symbol} $font-style - css font-style (normal or italic)
///
/// @example scss - Usages
/// @include lns-at-font-face('Ubuntu', 'ubuntu-regular-webfont', 'ubunturegular');
/// @include lns-at-font-face($font-family: 'Ubuntu', $file-name: 'ubuntu-regular-webfont', $svgid: 'ubunturegular');
/// @include lns-at-font-face($font-family: 'Ubuntu', $file-name: 'ubuntu-regular-webfont', $svgid: 'ubunturegular', $font-weight: 400, $font-style: normal);
///
/// @output css - CSS output
/// @font-face {
///   font-family: "Ubuntu";
///   src: url("../../fonts/ubuntu-regular-webfont.eot");
///   src: url("../../fonts/ubuntu-regular-webfont.eot?#iefix") format("embedded-opentype"),
///        url("../../fonts/ubuntu-regular-webfont.woff2") format("woff2"),
///        url("../../fonts/ubuntu-regular-webfont.woff") format("woff"),
///        url("../../fonts/ubuntu-regular-webfont.ttf") format("truetype"),
///        url("../../fonts/ubuntu-regular-webfont.svg#ubunturegular") format("svg");
///   font-weight: 400;
///   font-style: normal;
/// }
@mixin lns-at-font-face($font-family, $file-name, $svgid, $font-weight: 400, $font-local-weight: "Regular", $font-style: normal) {
	@if variable-exists(font-weight) {
		$font-weights: (100, 200, 300, 400, 500, 600, 700, 800, 900) !default;
		@if null == list.index($font-weights, $font-weight) {
			@error 'invalid value for $font-weight [100|200|300|400|500|600|700|800|900]';
			$font-weight: 400;
		}
	}
	@if variable-exists(font-style) {
		@if $font-style != normal and $font-style != italic {
			@error 'invalid value for $font-style [normal|italic]';
			$font-style: normal;
		}
	}

	$font-local-name-1: '' !default;
	$font-local-name-2: '' !default;
	@if 'Regular' == $font-local-weight {
		$font-local-name-1: $font-family;
	}
	@else {
		$font-local-name-1: $font-family + ' ' + $font-local-weight;
	}
	$font-local-name-2: $font-family + '-' + $font-local-weight;

	// @output
	@font-face {
		font-family: $font-family;
		font-style: $font-style;
		font-weight: $font-weight;
		src: url("../../fonts/#{$file-name}.eot");
		src: local("#{$font-local-name-1}"),
			 local("#{$font-local-name-2}"),
			 url("../../fonts/#{$file-name}.eot?#iefix") format("embedded-opentype"),
		     url("../../fonts/#{$file-name}.woff2") format("woff2"),
		     url("../../fonts/#{$file-name}.woff") format("woff"),
		     url("../../fonts/#{$file-name}.ttf") format("truetype"),
		     url("../../fonts/#{$file-name}.svg##{$svgid}") format("svg");
	}
}

/// mixin for device depended media queries w/o retina based on Bootstrap
///
/// @author schaffrath, Andre Schuessler
///
/// @access public
///
/// @param {String} $device - target device (xs|sm|md|lg|xl)
///
/// @param {String} $orientation - device orientation (all|landscape|portrait)
///
/// @param {Boolean} $retina - media query should target screens with high pixel density aka "retina"
///
/// @example scss - Usage
/// @include lns-at-media($device: 'sm') {
///	  .button {
///     background-image: url("../../images/button-bg.png");
///   }
/// }
/// @include lns-at-media($device: 'sm', $retina: true) {
///	  .button {
///     background-image: url("../../images/button-bg@2x.png");
///   }
/// }
///
/// @output css - CSS output
/// @media only screen and (min-width: 544px) and (max-width: 767px) {
///	  .button {
///     background-image: url("../../images/button-bg.png");
///   }
/// }
/// @media only screen and (min-width: 544px) and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 1.5) {
///	  .button {
///     background-image: url("../../images/button-bg@2x.png");
///   }
/// }
@mixin lns-at-media($device: 'xs', $orientation: 'all', $retina: false) {
	@if variable-exists(device) and variable-exists(orientation) and variable-exists(retina) {
		$device-rule: '' !default;
		$retina-rules: () !default;
		$orientation-rule: '' !default;
		$at-media: '' !default;
		$breakpoint: 0 !default;

		@if variable-exists(device) {
			$devices: ('xs', 'sm', 'md', 'lg', 'xl') !default;
			@if null == list.index($devices, $device) {
				@error 'invalid value for $device [xs|sm|md|lg|xl]';
				$device: 'xs';
			}

			$breakpoint: list.nth($grid-breakpoints, list.index($devices, $device));
			@if length($devices) == list.index($devices, $device) {
				$device-rule: 'only screen and (min-width: ' + list.nth($breakpoint, 2) + ')';
			}
			@else if list.index($devices, $device) > 1 {
				$breakpoint-larger: list.nth($grid-breakpoints, (list.index($devices, $device) + 1));
				//$device-rule: 'only screen and (min-width: ' + list.nth($breakpoint, 2) + ') and (max-width: ' + (list.nth($breakpoint-larger, 2) - 1px) + ')';
				$device-rule: 'only screen and (min-width: ' + list.nth($breakpoint, 2) + ')';
			}
		}
		@else {
			$device: 'xs';
		}
		@if variable-exists(orientation) {
			$orientations: ('all', 'landscape', 'portrait') !default;
			@if null == list.index($orientations, $orientation) {
				@error 'invalid value for $orientation [all|landscape|portrait]';
				$orientation: 'all';
			}

			@if $orientation == 'landscape' {
				$orientation-rule: '(orientation: landscape)';
			}
			@else if $orientation == 'portrait' {
				$orientation-rule: '(orientation: portrait)';
			}
		}
		@if variable-exists(retina) {
			@if $retina {
				// 2/192dpi|1.25/120dpi|1.3/124.8dpi|1.5/144dpi
				$retina-rules: (
					'(-webkit-min-device-pixel-ratio: 1.25)',
					'(min-resolution: 120dpi)'
				);
			}
		}

		@if $device-rule != '' or $orientation-rule != '' or $retina-rules != () {
			// assembling @media

			// device
			@if $device-rule != '' {
				$at-media: $device-rule;
			}

			// orientation
			@if $orientation-rule != '' {
				@if $at-media != '' {
					$at-media: $at-media + ' and ';
				}
				$at-media: $at-media + $orientation-rule
			}

			// retina
			@if $retina-rules != () {
				$at-media-existing: $at-media !default;
				$at-media-result: '' !default;
				@each $key in $retina-rules {
					@if $at-media-result != '' {
						$at-media-result: $at-media-result + ', ';
					}
					@if $at-media-existing != '' {
						$at-media-result: $at-media-result + $at-media-existing + ' and ' + $key;
					}
					@else {
						$at-media-result: $at-media-result + $key;
					}
				}
				$at-media: $at-media-result;
			}

			// @output
			@media #{$at-media} {
				@content;
			}
		}
		@else {
			// @output
			@content;
		}
	}
}
/* stylelint-enable indentation */

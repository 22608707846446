form.mobio-users {
    margin-top: 1rem;
    > div:not(:first-child) {
        margin-top: 3rem;
    }

    label,
    input[type="text"],
    input[type="password"],
    input[type="email"] {
        color: inherit;
        &::placeholder {
            color: inherit;
        }
    }
}
